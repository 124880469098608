import { LiveStreamEvents } from "../../constants/liveBeatIt";
import { GameSessionV2 } from "../../gql/graphql";
import { startLivestream } from "../../graphql/resolvers/mutation/gameSessions";
import {
  ILiveStreamMessage,
  ILiveStreamMessageReceived,
  IPlayer,
} from "../../types/liveStream";
import { LiveStreamWS } from "../liveStream";
import { QueryParams } from "../queryParam";

export const handleLiveStreamMessage = (
  message: ILiveStreamMessageReceived
): ILiveStreamMessage => {
  const { event, data } = message;
  const { sessionId } = data;

  switch (event) {
    case LiveStreamEvents.error:
      return {
        error: true,
        sessionId,
        code: data.code,
        message: data.message,
      };
    case LiveStreamEvents.playerDisconnected:
    case LiveStreamEvents.viewers:
    case LiveStreamEvents.sessionEnded:
    case LiveStreamEvents.sessionJoined:
    case LiveStreamEvents.sessionCreated:
      return { type: event, sessionId, data };
    case LiveStreamEvents.emoji:
      return { type: event, sessionId, emoji: data.emoji, sender: data.sender };
    case LiveStreamEvents.battleEnded:
    case LiveStreamEvents.newBattleStarted:
    case LiveStreamEvents.battleReady:
      return { type: event, sessionId, data };
    case LiveStreamEvents.controlRequest:
    case LiveStreamEvents.controlRequestDenied:
    case LiveStreamEvents.controlBackToHost:
    case LiveStreamEvents.controlRequestCancelled:
    case LiveStreamEvents.newPlayer:
      return { type: event, data, sessionId };
    default:
      return { sessionId, data };
  }
};

export const startLiveStreamSession = async (
  gameSession: GameSessionV2,
  wsConnectionId: string | null,
  liveStreamWS: LiveStreamWS
) => {
  if (!gameSession || QueryParams.isStreamSession()) return;

  try {
    const livestream = await startLivestream({
      gameSessionId: gameSession.id,
      wsConnectionId: wsConnectionId || "",
    });
    liveStreamWS.startSession({
      sessionId: livestream?.id,
      battleId: livestream?.battleId,
      edgeNodeId: livestream.edgeNodeId,
      gameSessionId: livestream.gameSessionId,
    });

    return {
      sessionId: livestream?.id,
      edgeNodeId: livestream.edgeNodeId,
      gameSessionId: livestream.gameSessionId,
      battleId: livestream?.battleId,
      isViewer: false,
      host: livestream.host,
    };
  } catch (error) {
    console.error(error);

    return null;
  }
};

export const getDifferenceViewer = (
  viewers: IPlayer[],
  newViewers: IPlayer[],
  viewerControl?: IPlayer,
  host?: IPlayer
) => {
  const findViewer = (id: string) => {
    return newViewers.find((newViewer) => newViewer.id === id);
  };
  return (
    viewers.filter(
      (viewer) =>
        !findViewer(viewer.id) &&
        viewer.id !== viewerControl?.id &&
        viewer.id !== host?.id
    ) || []
  );
};
