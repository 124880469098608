import apollo from "../../../clients/apollo";
import {
  getGameGQL,
  listHotBattleGQL,
  getUserGameSkillsGQL,
  getUserMostPlayedGamesGQL,
  getHouseBattleByGameIdGQL,
} from "../../queries/games";
import { IParamsGetGame } from "../../../types/games";
import { MomentStatus } from "../../../constants/moments";
import { pick } from "lodash-es";
import { QueryOptions } from "@apollo/client";
import { HouseBattle, Query } from "../../../gql/graphql";

const apolloClient = apollo.getInstance();

export const getHouseBattleByGameId = async (
  id: string,
  options: Partial<QueryOptions> = {}
): Promise<HouseBattle[]> => {
  const res = await apolloClient.query({
    ...options,
    query: getHouseBattleByGameIdGQL,
    variables: {
      getGameInput: { id },
    },
  });
  if (res?.errors) {
    throw res?.errors[0];
  }

  return res.data.getGame.houseBattles;
};

export const getGame = async (
  params: IParamsGetGame,
  options: Partial<QueryOptions> = {}
): Promise<Query["getGame"]> => {
  const gameInput = {
    id: params.id || "",
    momentStatus: params.momentStatus || [MomentStatus.Live],
    leaderboardLimit: params.momentStatus || 10,
    leaderboardOffset: params.momentStatus || 0,
  };

  const res = await apolloClient.query({
    ...options,
    query: getGameGQL,
    variables: {
      getGameInput: pick(gameInput, ["id", "momentStatus"]),
      getLeaderboardInput: {
        limit: gameInput.leaderboardLimit,
        offset: gameInput.leaderboardOffset,
      },
    },
  });

  if (res?.errors) {
    throw res?.errors[0];
  }

  return res.data.getGame;
};

export const listHotBattle = async (
  options: Partial<QueryOptions> = {}
): Promise<Query["listHotBattles"]> => {
  const response = await apolloClient.query({
    ...options,
    query: listHotBattleGQL,
    variables: {},
  });

  return response.data.listHotBattles;
};

export const fetchGameSkills = async (
  userId?: string,
  options = {}
): Promise<Query["listUserGameSkills"]> => {
  const res = await apolloClient.query({
    ...options,
    query: getUserGameSkillsGQL,
    variables: { listUserGameSkillsInput: { userId } },
  });

  return res.data.listUserGameSkills;
};

export const listUserMostPlayedGames = async (
  options: Partial<QueryOptions> = {},
  userId?: string
): Promise<Query["getUserMostPlayedGames"]> => {
  const res = await apolloClient.query({
    ...options,
    query: getUserMostPlayedGamesGQL,
    variables: { getUserMostPlayedGamesInput: { userId } },
  });

  return res.data.getUserMostPlayedGames;
};
