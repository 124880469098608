import {
  PayloadAction,
  createAction,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  IActionStream,
  IEmojiStreamAction,
  IEndBattleMetadata,
  IPlayer,
} from "../types/liveStream";
import { AppDispatch, AsyncAppThunk, RootState } from "../store";
import { OnmoLocation } from "../models/onmoLocation";
import { compact, sortBy, uniqBy } from "lodash";
import { PublicUser } from "../gql/graphql";
import { searchUser } from "../graphql/resolvers/queries/users";
import { setNotificationError } from "./alert";
import { pushAnimation } from "./animations";

export const VIEWER_SESSION_SUB_STATES = {
  NONE: 0,
  STREAM_ENDED: 258,
  ERROR_LIVE_FULL_SEATS: 20,
  ERROR_LIVE_RECONNECT: 132,
};

interface ISessionParams {
  sessionId: string;
  edgeNodeId: string;
  gameSessionId: string;
  battleId: string;
  isViewer: boolean;
}

interface ILiveStream {
  sessionParams: ISessionParams;
  viewerSessionState: number;
  listViewer: IPlayer[];
  viewerControl?: IPlayer;
  host?: IPlayer;
  listViewerFollowStatus?: PublicUser[];
  actionList: IActionStream[];
  emojiList: IEmojiStreamAction[];
  endBattleMetadata?: IEndBattleMetadata;
}

const initialState = {
  sessionParams: {
    sessionId: "",
    edgeNodeId: "",
    battleId: "",
    gameSessionId: "",
    isViewer: false,
  },
  viewerSessionState: VIEWER_SESSION_SUB_STATES.NONE,
  listViewer: [],
  actionList: [],
  emojiList: [],
  listViewerFollowStatus: [],
  viewerControl: undefined,
  endBattleMetadata: undefined,
  host: undefined,
} as ILiveStream;

export const resetSessionLivestream = createAction("livestream/resetState");

export const livestreamSlice = createSlice({
  name: "livestream",
  initialState: initialState,
  reducers: {
    updateSessionParams: (
      state,
      action: PayloadAction<{ sessionParams: ISessionParams }>
    ) => {
      state.sessionParams = {
        ...action.payload.sessionParams,
      };
    },
    updateSessionState: (
      state,
      action: PayloadAction<{ viewerSessionState: number }>
    ) => {
      state.viewerSessionState = action.payload.viewerSessionState;
    },
    setListViewer: (
      state,
      action: PayloadAction<{ listViewer: IPlayer[] }>
    ) => {
      state.listViewer = action.payload.listViewer;
    },
    setListViewerFollowStatus: (
      state,
      action: PayloadAction<{ listViewerFollowStatus: PublicUser[] }>
    ) => {
      state.listViewerFollowStatus = action.payload.listViewerFollowStatus;
    },
    setViewerControl: (
      state,
      action: PayloadAction<{ viewerControl?: IPlayer }>
    ) => {
      state.viewerControl = action.payload.viewerControl;
    },
    setHost: (state, action: PayloadAction<{ host?: IPlayer }>) => {
      state.host = action.payload.host;
    },
    pushActionList: (
      state,
      action: PayloadAction<{ action: IActionStream }>
    ) => {
      state.actionList = state.actionList
        .concat(action.payload.action)
        .slice(-100);
    },
    pushEmojiList: (
      state,
      action: PayloadAction<{ emoji: IEmojiStreamAction }>
    ) => {
      const randomViewer =
        state.listViewer[Math.floor(Math.random() * state.listViewer.length)];
      const actionPush = {
        ...action.payload.emoji,
        sender: action.payload.emoji.sender || randomViewer,
      };

      state.emojiList = state.emojiList.concat(actionPush).slice(-100);
    },
    resetEmojiList: (
      state,
      action: PayloadAction<{ emojiList: IEmojiStreamAction[] }>
    ) => {
      state.emojiList = action.payload.emojiList || [];
    },
    resetActionList: (
      state,
      action: PayloadAction<{ action: IActionStream[] }>
    ) => {
      state.actionList = action.payload.action || [];
    },
    popHelpAction: (state) => {
      state.actionList = state.actionList.filter(
        (item) => item.action !== "ls_needs help"
      );
    },
    setEndBattleMetadata: (
      state,
      action: PayloadAction<{ endBattleMetadata?: IEndBattleMetadata }>
    ) => {
      state.endBattleMetadata = action.payload.endBattleMetadata;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetSessionLivestream, () => initialState);
  },
});

// Action creators are generated for each case reducer function
export const {
  updateSessionParams,
  updateSessionState,
  setListViewer,
  setListViewerFollowStatus,
  setHost,
  setViewerControl,
  pushActionList,
  resetActionList,
  popHelpAction,
  setEndBattleMetadata,
  pushEmojiList,
  resetEmojiList,
} = livestreamSlice.actions;

export const fetchFollowStatusViewers =
  (listUserId: string[]): AsyncAppThunk =>
  async (dispatch, getState) => {
    try {
      const results = await searchUser({ userIds: listUserId });
      const state = getState();
      const listViewerFollowStatus = state.liveStream.listViewerFollowStatus;

      dispatch(
        setListViewerFollowStatus({
          listViewerFollowStatus: uniqBy(
            results.items.concat(listViewerFollowStatus ?? []),
            "id"
          ),
        })
      );
    } catch (e) {
      if (e instanceof Error) {
        (dispatch as AppDispatch)(setNotificationError(e.message));
      }
    }
  };

export const updateEndBattleMetadata =
  (endBattleMetadata?: string): AsyncAppThunk =>
  async (dispatch) => {
    const endBattleMetadataParsed = endBattleMetadata
      ? JSON.parse(endBattleMetadata || "undefined")
      : undefined;

    if (endBattleMetadataParsed?.isShowRoadmap) {
      dispatch(pushAnimation({ animationType: "mapAnimation", order: 1 }));
    }
    dispatch(
      setEndBattleMetadata({ endBattleMetadata: endBattleMetadataParsed })
    );
  };

export const isControllingStream = createSelector(
  [(state: RootState) => state.liveStream, (state: RootState) => state.user],
  (liveStreamState, userState) => {
    const isViewStream = OnmoLocation.isViewLivestream();

    const isControlStream = !isViewStream
      ? !liveStreamState.viewerControl
      : liveStreamState.viewerControl?.id === userState.me?.id;

    return isControlStream;
  }
);

export const listMessageSelector = createSelector(
  [(state: RootState) => state.liveStream.actionList],
  (actionList) => {
    return sortBy(actionList, (a) => a.action === "ls_needs help");
  }
);
export const listUserSelector = createSelector(
  [(state: RootState) => state.liveStream],
  (livestream) => {
    const { host, viewerControl, listViewer } = livestream;
    return compact(uniqBy([host, viewerControl].concat(listViewer), "id"));
  }
);

export const isViewerDisconnectedSelector = createSelector(
  [(state: RootState) => state.liveStream.viewerSessionState],
  (viewerSessionState) => {
    return (
      viewerSessionState === VIEWER_SESSION_SUB_STATES.ERROR_LIVE_RECONNECT
    );
  }
);
export const isStreamEndedSelector = createSelector(
  [(state: RootState) => state.liveStream.viewerSessionState],
  (viewerSessionState) => {
    return viewerSessionState === VIEWER_SESSION_SUB_STATES.STREAM_ENDED;
  }
);
export const isStreamFullSelector = createSelector(
  [(state: RootState) => state.liveStream.viewerSessionState],
  (viewerSessionState) => {
    return (
      viewerSessionState === VIEWER_SESSION_SUB_STATES.ERROR_LIVE_FULL_SEATS
    );
  }
);

export default livestreamSlice.reducer;
