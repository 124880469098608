import { gql } from "graphql-tag";

export const meGQL = gql`
  query Me {
    me {
      avatar
      email
      idpName
      pushSubscription
      xp
      id
      idpId
      username
      language
      metadata {
        desiredStreamResolution
        hasReceivedWelcomeCoins
        hasSeenWelcomePopup
        lastSeenTransactionId
        onboarding
      }
    }
  }
`;

export const myWalletsGQL = gql`
  query Me {
    me {
      coinWallets {
        balance
        userId
        walletType
        itemType
      }
    }
  }
`;

export const userGQL = gql`
  query User($userId: ID!) {
    user(id: $userId) {
      id
      username
      coinWallets {
        balance
        userId
        walletType
        itemType
      }
    }
  }
`;
