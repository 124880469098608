/* eslint-disable */
export type Maybe<T> = T;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export enum CoinType {
  Basic = "basic",
  Gems = "gems",
  SpendableGems = "spendable_gems",
}

/** Denotes a coin wallet */
export type CoinWallet = {
  __typename: "CoinWallet";
  /** Coin balance */
  balance: Scalars["Int"]["output"];
  /** Coin type */
  itemType: CoinType;
  /** User Id */
  userId: Scalars["String"]["output"];
  /** Wallet Type */
  walletType: WalletType;
};

export type Game = {
  __typename: "Game";
  /** Category of the game (e.g., racing, arcade puzzle) */
  category: Scalars["String"]["output"];
  /** Dynamic metadata which gives additional information about the game */
  data: Maybe<Scalars["String"]["output"]>;
  /** The description of the game */
  description: Scalars["String"]["output"];
  /** Indicates if the game is featured game */
  featured: Scalars["Boolean"]["output"];
  /** Indicates if the game has a BATTLE type moment */
  hasBattle: Scalars["Boolean"]["output"];
  /** Indicates if the game has a CASUAL type moment */
  hasSolo: Scalars["Boolean"]["output"];
  /** Indicates if the game has a OPEN status tournament */
  hasTournament: Scalars["Boolean"]["output"];
  /** Unique identifier for the game */
  id: Scalars["String"]["output"];
  /** Mode of rotation for the game */
  rotationMode: RotationMode;
  /** Current status of the game */
  status: GameStatus;
  /** The title of the game */
  title: Scalars["String"]["output"];
  /** Type of the game */
  type: GameType;
};

export enum GameStatus {
  Disabled = "DISABLED",
  Draft = "DRAFT",
  Live = "LIVE",
  Upcoming = "UPCOMING",
}

export enum GameType {
  Embed = "EMBED",
  Html = "HTML",
  Stream = "STREAM",
}

export type GamesOutput = PaginatedQueryOutput & {
  __typename: "GamesOutput";
  items: Array<Game>;
  total: Scalars["Int"]["output"];
};

export type Health = {
  __typename: "Health";
  status: HealthStatus;
};

export enum HealthStatus {
  Error = "ERROR",
  Ok = "OK",
}

export type ListGamesInput = {
  page: InputMaybe<PaginatedQueryInput>;
  status: InputMaybe<Array<InputMaybe<GameStatus>>>;
};

export type Message = {
  __typename: "Message";
  message: Scalars["String"]["output"];
};

export type Mutation = {
  __typename: "Mutation";
  followUser: Message;
  updateUser: Message;
  updateUserMetadata: Message;
};

export type MutationFollowUserArgs = {
  userId: Scalars["ID"]["input"];
};

export type MutationUpdateUserArgs = {
  input: UserUpdateInput;
};

export type MutationUpdateUserMetadataArgs = {
  input: UserMetadataInput;
};

export type Pageable = Game | Tenant | User;

export type PaginatedQueryInput = {
  limit: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
};

export type PaginatedQueryOutput = {
  items: Array<Pageable>;
  total: Scalars["Int"]["output"];
};

export type Query = {
  __typename: "Query";
  games: GamesOutput;
  health: Health;
  me: User;
  tenant: Maybe<Tenant>;
  tenants: TenantsOutput;
  user: Maybe<User>;
  users: Maybe<UsersOutput>;
};

export type QueryGamesArgs = {
  input: ListGamesInput;
};

export type QueryTenantArgs = {
  id: Scalars["String"]["input"];
};

export type QueryTenantsArgs = {
  page: InputMaybe<PaginatedQueryInput>;
};

export type QueryUserArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryUsersArgs = {
  page: InputMaybe<PaginatedQueryInput>;
};

export enum RotationMode {
  Any = "ANY",
  Landscape = "LANDSCAPE",
  Portrait = "PORTRAIT",
}

export type Tenant = {
  __typename: "Tenant";
  /** Tenant's Front End URL */
  appUrl: Scalars["String"]["output"];
  /** Client Id used for the tenant Id in Cognito */
  clientId: Scalars["String"]["output"];
  /** Id of the tenant set */
  id: Scalars["String"]["output"];
  /** Default language code of the tenant */
  languageCode: Scalars["String"]["output"];
  /** Name of the tenant */
  name: Scalars["String"]["output"];
  /** Short name for the tenant */
  shortCode: Scalars["String"]["output"];
  /** Tenant status which denotes, if tenant is live or is disabled */
  status: TenantStatus;
};

export enum TenantStatus {
  Disabled = "DISABLED",
  Live = "LIVE",
}

export type TenantsOutput = PaginatedQueryOutput & {
  __typename: "TenantsOutput";
  items: Array<Tenant>;
  total: Scalars["Int"]["output"];
};

export type User = {
  __typename: "User";
  /** Avatar set for the user */
  avatar: Scalars["String"]["output"];
  /** Banner of the user */
  banner: Scalars["String"]["output"];
  /** User's coin wallets */
  coinWallets: Array<CoinWallet>;
  /** User's account status */
  disabled: Scalars["Boolean"]["output"];
  /** User's email address */
  email: Maybe<Scalars["String"]["output"]>;
  /** Facebook's idp id */
  facebookId: Maybe<Scalars["String"]["output"]>;
  /** Google's idp Id */
  googleId: Maybe<Scalars["String"]["output"]>;
  /** ID of the user */
  id: Scalars["ID"]["output"];
  /** Common IDP ID of the user. `null` when user signed up using phone or email */
  idpId: Maybe<Scalars["String"]["output"]>;
  /** IDP Provider Name. `null` when user signed up using phone or email */
  idpName: Maybe<Scalars["String"]["output"]>;
  /** User's desired language */
  language: Maybe<Scalars["String"]["output"]>;
  /** User's metadata */
  metadata: Maybe<UserMetadata>;
  /** User's phone number */
  phone: Maybe<Scalars["String"]["output"]>;
  /** User's push subscription endpoint */
  pushSubscription: Maybe<Scalars["String"]["output"]>;
  /** Username of the user */
  username: Scalars["String"]["output"];
  /** user's xp value */
  xp: Scalars["Int"]["output"];
};

export type UserMetadata = {
  __typename: "UserMetadata";
  desiredStreamResolution: Maybe<Scalars["Int"]["output"]>;
  hasReceivedWelcomeCoins: Scalars["Boolean"]["output"];
  hasSeenWelcomePopup: Scalars["Boolean"]["output"];
  lastSeenTransactionId: Maybe<Scalars["String"]["output"]>;
  onboarding: Maybe<Scalars["String"]["output"]>;
};

export type UserMetadataInput = {
  desiredStreamResolution: InputMaybe<Scalars["Int"]["input"]>;
  hasReceivedWelcomeCoins: InputMaybe<Scalars["Boolean"]["input"]>;
  hasSeenWelcomePopup: InputMaybe<Scalars["Boolean"]["input"]>;
  lastSeenTransactionId: InputMaybe<Scalars["String"]["input"]>;
  onboarding: InputMaybe<Scalars["String"]["input"]>;
};

export type UserUpdateInput = {
  /** Banner to be set for user */
  banner: InputMaybe<Scalars["String"]["input"]>;
  /** To disable or enable the user. */
  disable: InputMaybe<Scalars["Boolean"]["input"]>;
  /** ID of the user to be updated. The field is only required when user update is being performed by ADMINs */
  id: InputMaybe<Scalars["ID"]["input"]>;
  /** To set the desired language by the user */
  language: InputMaybe<Scalars["String"]["input"]>;
  /** To set push subscription value for the user */
  pushSubscription: InputMaybe<Scalars["String"]["input"]>;
  /** New username for the user */
  username: InputMaybe<Scalars["String"]["input"]>;
};

export type UsersOutput = PaginatedQueryOutput & {
  __typename: "UsersOutput";
  items: Array<User>;
  total: Scalars["Int"]["output"];
};

export enum WalletType {
  Achievement = "achievement",
  Coin = "coin",
  Inventory = "inventory",
}
