import React, { FC, ImgHTMLAttributes, useEffect, useState } from "react";
import clsx from "classnames";
import { useStyles } from "./Avatar.style";
import { Image } from "../../core/Image/Image";
import Avatar1x from "./assets/avatar-light_1x.webp";
import Avatar2x from "./assets/avatar-light_2x.webp";
import Avatar3x from "./assets/avatar-light_3x.webp";
import Game1x from "./assets/game-error_1x.webp";
import Game2x from "./assets/game-error_2x.webp";
import Game3x from "./assets/game-error_3x.webp";
import CheckedIcon from "./assets/check_circle_1x.webp";
import CheckedIcon2x from "./assets/check_circle_2x.webp";
import CheckedIcon3x from "./assets/check_circle_3x.webp";
import CrownIcon from "../../common/ResultAnimation/assets/won_battle_1x.webp";
import CrownIcon2x from "../../common/ResultAnimation/assets/won_battle_2x.webp";
import CrownIcon3x from "../../common/ResultAnimation/assets/won_battle_3x.webp";
import { BadgeGame } from "../../core/BadgeGame/BadgeGame";
import { BorderRadius } from "../../../constants/borderRadius";
import { ProgressBar } from "../../core/ProgressBar";
import { RankColor, UserStatus } from "../../../constants/user";
import { ILevel } from "../../../types/user";
import { StaticImage } from "../../common/StaticImage";
import { isString } from "lodash-es";
import { NB_SECOND_SHOW_PROGRESS } from "../../../constants/endscreen";
import { isLevelUp } from "../../../models/userFactory/userFactory";
import { findGameImage, getHighResGameImage } from "../../../models/game/game";
import { useAppSelector } from "../../../hooks";

interface IAvatarProps {
  url?: string;
  size: AvatarSizeT;
  picking?: "unselected" | "selected";
  showProgress?: boolean;
  onClickBadge?: () => void;
  userRank?: ILevel;
  status?: UserStatus | React.ReactNode;
  crownIcon?: boolean;
  gameId?: string;
  isRankAnimation?: boolean;
  nbGem?: number;
  hideRank?: boolean;
  nextRank?: ILevel;
  isHideRankId?: boolean;
  rootClasses?: string;
}

export type AvatarSizeT = "s" | "m" | "l" | "xl" | "xxl" | "xxxl";

const defaultProfileSrcSet = `${Avatar1x} 1x, ${Avatar2x} 2x, ${Avatar3x} 3x`;
const defaultGameSrcSet = `${Game1x} 1x, ${Game2x} 2x, ${Game3x} 3x`;

export const AvatarWidthMapping = {
  s: 40,
  m: 60,
  l: 80,
  xl: 120,
  xxl: 163.5,
  xxxl: 255,
};
export const AvatarHeightMapping = {
  s: 55,
  m: 80,
  l: 108,
  xl: 162,
  xxl: 220.274,
  xxxl: 340,
};

export const Avatar: FC<IAvatarProps & ImgHTMLAttributes<HTMLImageElement>> = ({
  alt,
  url,
  size,
  userRank,
  picking,
  showProgress,
  onClickBadge,
  status,
  crownIcon,
  gameId,
  isRankAnimation,
  nbGem,
  hideRank,
  nextRank,
  isHideRankId,
  rootClasses,
  className,
  ...otherProps
}) => {
  const classes = useStyles(nbGem || 0) as any;
  const { listGame } = useAppSelector((state) => state.game);
  const [srcSet, setSrcSet] = useState<string | undefined>(url);
  const [isFinishedAnimation, setFinishedAnimation] = useState(false);
  const isRankUp = !!userRank && isLevelUp(userRank, nbGem || 0);
  const isSmallSize = ["s", "m", "l"].includes(size);

  const avatarWidth = AvatarWidthMapping[size];
  const avatarHeight = !gameId
    ? AvatarWidthMapping[size]
    : AvatarHeightMapping[size];
  const avatarRadius = !gameId
    ? "50%"
    : isSmallSize
    ? BorderRadius.sm
    : BorderRadius.md;

  const onError = () => {
    if (!isString(url) && !gameId) return;
    setSrcSet(!gameId ? defaultProfileSrcSet : defaultGameSrcSet);
  };

  const getUserAvatar = () => {
    if (gameId) {
      const currentGameImage = findGameImage(listGame, {
        gameId,
        type: isSmallSize ? "small" : "big",
      });
      return getHighResGameImage(currentGameImage).png || "";
    } else {
      if (url && url.includes("http")) {
        return url;
      }
      return Avatar3x;
    }
  };

  const getSizeBadge = () => {
    switch (size) {
      case "s":
        return "m";
      case "m":
        return "xl";
      case "l":
        return "xxl";
    }
  };

  const getSizeProgress = () => {
    switch (size) {
      case "s":
        return "small";
      case "m":
        return "medium";
      case "l":
        return "large";
    }
  };

  useEffect(() => {
    if (!isRankAnimation || !isRankUp) return;
    const timeoutProgress = setTimeout(() => {
      setFinishedAnimation(true);
    }, NB_SECOND_SHOW_PROGRESS);
    return () => {
      clearTimeout(timeoutProgress);
    };
  }, [isRankAnimation, isRankUp]);

  return (
    <div className={clsx(classes.roundedAvatar, picking, rootClasses)}>
      {picking === "selected" && (
        <img
          srcSet={`${CheckedIcon} 1x, ${CheckedIcon2x} 2x, ${CheckedIcon3x} 3x`}
          alt="CheckedIcon"
          className={classes.checkedIcon}
        />
      )}
      {gameId && !listGame ? (
        <Image
          {...otherProps}
          srcSet=""
          className={clsx(classes.root, "game", className)}
          width={avatarWidth}
          height={avatarHeight}
          radius={avatarRadius}
          url=""
          alt={alt}
        />
      ) : (
        <Image
          {...otherProps}
          srcSet={srcSet || getUserAvatar() || url}
          onError={onError}
          className={clsx(classes.root, gameId && "game", className)}
          width={avatarWidth}
          height={avatarHeight}
          radius={avatarRadius}
          url={getUserAvatar()}
          alt={alt}
          key={srcSet || getUserAvatar() || url}
        />
      )}
      {userRank?.id && getSizeBadge() && (
        <BadgeGame
          size={getSizeBadge()}
          type={
            isFinishedAnimation && nextRank?.id ? nextRank?.id : userRank.id
          }
          className={clsx(
            classes.rank,
            getSizeBadge(),
            !isFinishedAnimation && isRankAnimation && classes.rankAnimation,
            hideRank && !isHideRankId && classes.hideRank
          )}
          onClick={onClickBadge}
          id={showProgress && !isHideRankId ? "rank-badge" : ""}
          data-testid="rank-badge"
        />
      )}
      {crownIcon && (
        <StaticImage
          src={CrownIcon}
          src2x={CrownIcon2x}
          src3x={CrownIcon3x}
          className={classes.crownIcon}
        />
      )}
      {status &&
        (typeof status === "string" ? (
          <div className={clsx(classes.status, status, size)} />
        ) : (
          status
        ))}
      {showProgress &&
        getSizeProgress() &&
        userRank &&
        !isFinishedAnimation && (
          <ProgressBar
            color={userRank.color || RankColor.Unranked}
            value={{
              initial: userRank?.currentXp || 0,
              total: userRank?.nextLevelXpNeeded || 100,
              target: (userRank?.currentXp || 0) + (nbGem || 0),
            }}
            type="circle"
            size={getSizeProgress()}
            className={classes.progress}
            isDisableAnimation={!isRankAnimation}
          />
        )}
      {showProgress && getSizeProgress() && nextRank && isFinishedAnimation && (
        <ProgressBar
          color={nextRank?.color || RankColor.Unranked}
          value={{
            initial: nextRank?.currentXp || 0,
            total: nextRank?.nextLevelXpNeeded || 100,
            target: nextRank?.currentXp || 0,
          }}
          type="circle"
          size={getSizeProgress()}
          className={classes.progress}
        />
      )}
    </div>
  );
};

Avatar.defaultProps = {
  size: "m",
};
