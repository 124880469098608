export enum PageRoutes {
  GameDetail = "/game",
  HomePage = "/",
  UserProfile = "/user",
  Leaderboard = "/leaderboard",
  Games = "/games",
  OnmoShop = "/onmo-shop",
  SignInEmail = "/signin/email",
  SignInPhone = "/signin/phone",
  SignUpEmail = "/signup/email",
  ResetPassword = "/reset-password",
  PlayBattle = "/play/battle",
  PlayTournament = "/play/tournament",
  Onboarding = "/onboarding",
  Faq = "/faq",
  PrivacyPolicy = "/privacy-policy",
  TermAndCondition = "/term-condition",
  CookiesPolicy = "/cookies-policy",
  LiveStream = "/live-stream",
  GoLive = "/go-live",
  FeaturedContest = "/feature-contest",
  SignInProvider = "/signin/provider",
  EditProfile = "/edit-profile",
  Friends = "/friends",
  FriendsSearch = "/friends/search",
  GamesSearch = "/games/search",
  FriendBattle = "/friends/select-game",
  Settings = "/settings",
  MyProfile = "/me",
  GemsInfo = "/gems-info",
  Landing = "/landing",
  PlayChallenge = "/play/challenge",
  TransactionHistory = "/transaction-history",
  TransactionHistorySearch = "/transaction-history/search",
  MWCLeaderboard = "/mwc-leaderboard",
  SelectGame = "/select-game",
  GlobalSearch = "/search",
  Menu = "/menu",
  DetailLevel = "/detail-level",
  Guest = "/guest",
  PlaySolo = "/play/solo",
  AboutUs = "/about-us",
  Shop = "/shop",
  BundleDetail = "/shop/bundle",
}

export enum SearchType {
  Game = "game",
  Friend = "friend",
}
