import apollo from "../../../clients/apollo";
import {
  createMutualFollowCodeGQL,
  followUserGQL,
  unfollowUserGQL,
} from "../../mutations/friends";
import { Mutation } from "../../../gql/graphql";

const apolloClient = apollo.getInstance();

export const followUser = async (
  userId: string
): Promise<Mutation["followUser"]> => {
  const res = await apolloClient.mutate({
    mutation: followUserGQL,
    variables: { followUserInput: { userId } },
  });

  return res.data.followUser;
};

export const unfollowUser = async (
  userId: string
): Promise<Mutation["unfollowUser"]> => {
  const res = await apolloClient.mutate({
    mutation: unfollowUserGQL,
    variables: { unfollowUserInput: { userId } },
  });

  return res.data.unfollowUser;
};

export const createMutualFollowCode = async (): Promise<
  Mutation["createMutualFollowCode"]
> => {
  const res = await apolloClient.mutate({
    mutation: createMutualFollowCodeGQL,
  });

  return res.data.createMutualFollowCode;
};
