import { Inventory_Category } from "../gql/graphql";

export enum Currency {
  Xxx = "XXX",
  Inr = "INR",
  Btc = "BTC",
}

export enum ActionWallet {
  Withdraw = "Withdraw",
  Deposit = "Deposit",
}

export enum TransactionStatus {
  Completed = "completed",
  Failed = "failed",
  Pending = "pending",
  Error = "ERROR",
}

export enum TransactionStatusComplete {
  Error = "ERROR",
  Active = "ACTIVE",
}

export enum ShopTab {
  All = "All",
  Owned = "Owned",
  Bundles = "Bundles",
  Avatars = "Avatars",
}

export const INVENTORIES_CATEGORY = [Inventory_Category.Avatar];

export const LIST_TAB_SHOP = [
  ShopTab.All,
  ShopTab.Owned,
  ShopTab.Bundles,
  ...INVENTORIES_CATEGORY,
];

export const TABS = [
  {
    id: "all_categories",
    title: "tab_All Categories",
  },
  {
    id: "coins_paid,coins_refunded",
    title: "tab_Game Transactions",
  },
  {
    id: "coins_purchased,coins_adjusted,bonus_coins_received,subscription_coins_received,topup_coins_received",
    title: "tab_Purchase & Bonus",
  },
];
