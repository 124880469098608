import { createUseStyles } from "react-jss";
import { SpacingMapping } from "../../../constants/spacing";
import { ZIndex } from "../../../constants/zIndex";
import { DarkThemeColors } from "../../../constants/colors";
import { GridPaddingMapping } from "../../../constants/padding";

export const useStyles = createUseStyles({
  root: {
    height: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    position: "relative",
    zIndex: ZIndex.dialog + 100,
    background: DarkThemeColors.Background1,
    padding: `0 ${GridPaddingMapping.xxh}px`,
  },
  description: {
    width: "90%",
  },
  button: {
    height: 34,
    marginTop: SpacingMapping.space16,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  backToLogin: {
    marginTop: SpacingMapping.space16,
  },
});
