import { setContext } from "@apollo/client/link/context";
import { ONMO_CONFIG } from "../constants/onmo";
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
} from "@apollo/client";
import { fetchAuthSession } from "aws-amplify/auth";

const httpLink = createHttpLink({
  uri: ONMO_CONFIG?.api?.httpApolloUrl,
  includeExtensions: true,
});

const legacyHttpLink = createHttpLink({
  uri: ONMO_CONFIG?.api?.legacyHttpApolloUrl,
  includeExtensions: true,
});

const primaryLegacyHttpLink = createHttpLink({
  uri: ONMO_CONFIG?.api?.primaryLegacyHttpApolloUrl,
  includeExtensions: true,
});

const authLink = setContext(async (_, { headers, apiName }) => {
  const currentSession = await fetchAuthSession();
  const accessToken = currentSession.tokens?.accessToken.toString();

  return {
    headers: {
      ...headers,
      authorization:
        apiName === "v2" ? `Bearer ${accessToken}` : accessToken || "",
    },
  };
});
let client: ApolloClient<NormalizedCacheObject> | null = null;

/**
 * Apollo client singleton supporting secured websocket and https transport.
 */
export const getInstance = (): ApolloClient<NormalizedCacheObject> => {
  if (client) return client;

  client = new ApolloClient({
    queryDeduplication: false,
    link: ApolloLink.split(
      (operation) => operation.getContext().apiName === "v2",
      ApolloLink.from([authLink, httpLink]),
      ApolloLink.split(
        (operation) => operation.getContext().apiName === "primaryLegacy",
        ApolloLink.from([authLink, primaryLegacyHttpLink]),
        ApolloLink.from([authLink, legacyHttpLink])
      )
    ),
    cache: new InMemoryCache({}),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
        errorPolicy: "ignore",
      },
      query: {
        fetchPolicy: "network-only",
        errorPolicy: "all",
      },
    },
  });

  return client;
};

const apollo = {
  getInstance,
};

export default apollo;
