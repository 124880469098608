import apollo from "../../../clients/apollo";
import {
  updateUserV2GQL,
  deleteUserV2GQL,
  getFreeCoinsGQL,
  checkinUserGQL,
  loginGQL,
  CreateShareableUrlGQL,
  updateUserMetadataGQL,
  ClaimLoginRewardGQL,
  disableUserGQL,
} from "../../mutations/users";
import { ILoginInput, IParamsUpdateUser } from "../../../types/user";
import { QueryParams } from "../../../models/queryParam";
import {
  ClaimLoginRewardInput,
  CreateShareableUrlInput,
  Mutation,
} from "../../../gql/graphql";
import { MutationOptions } from "@apollo/client";

const apolloClient = apollo.getInstance();

export const updateUser = async (
  updateUserV2Input: IParamsUpdateUser
): Promise<Mutation["updateUserV2"]> => {
  const res = await apolloClient.mutate({
    mutation: updateUserV2GQL,
    variables: { updateUserV2Input },
  });

  return res.data.updateUserV2;
};

export const deleteUser = async (): Promise<Mutation["deleteUserV2"]> => {
  const res = await apolloClient.mutate({
    mutation: deleteUserV2GQL,
    variables: {},
  });

  return res.data.deleteUserV2;
};

export const disableUser = async (): Promise<Mutation["disableUser"]> => {
  const res = await apolloClient.mutate({
    mutation: disableUserGQL,
    variables: {},
  });

  return res.data.deleteUserV2;
};

export const getFreeCoins = async (): Promise<Mutation["getFreeCoins"]> => {
  const res = await apolloClient.mutate({
    mutation: getFreeCoinsGQL,
    variables: {},
  });

  return res.data.getFreeCoins;
};

export const checkinUser = async (): Promise<Mutation["checkinUser"]> => {
  const userAgent = JSON.stringify(window.navigator.userAgent);
  const utmMedium = JSON.stringify(QueryParams.getUtmMedium());
  const utmSource = JSON.stringify(QueryParams.getUtmSource());
  const utmCampaign = JSON.stringify(QueryParams.getUtmCampaign());
  const res = await apolloClient.mutate({
    mutation: checkinUserGQL,
    variables: {
      input: { userAgent, utmSource, utmMedium, utmCampaign },
    },
  });

  return res.data.checkinUser;
};

const prioritizeOncode = (input?: ILoginInput) => {
  if (input?.oncode && input?.encryptedOidcState) {
    delete input.encryptedOidcState;
  }
  return input;
};

export const login = async (
  loginInput?: ILoginInput
): Promise<Mutation["login"]> => {
  const res = await apolloClient.mutate({
    mutation: loginGQL,
    variables: {
      loginInput: prioritizeOncode(loginInput),
    },
  });

  return res.data.login;
};

export const createShareableUrlGQL = async (
  input: CreateShareableUrlInput
): Promise<Mutation["createShareableUrl"]> => {
  const res = await apolloClient.mutate({
    context: { apiName: "primaryLegacy" },
    mutation: CreateShareableUrlGQL,
    variables: { createShareableUrlInput: input },
  });

  return res.data.createShareableUrl.url;
};
export const claimLoginReward = async (
  input: ClaimLoginRewardInput
): Promise<Mutation["claimLoginReward"]> => {
  const res = await apolloClient.mutate({
    mutation: ClaimLoginRewardGQL,
    variables: { claimLoginRewardInput: input },
  });

  return res.data.claimLoginReward;
};

export const updateUserMetadata = async (
  metadata:
    | { hasSeenWelcomePopup: true }
    | { hasReceivedWelcomeCoins: true }
    | { onboarding: string | null }
    | { lastSeenTransactionId: string | null }
    | { desiredStreamResolution: number | null },
  options: Partial<MutationOptions> = {}
): Promise<Mutation["updateUserMetadata"]> => {
  const res = await apolloClient.mutate({
    ...options,
    mutation: updateUserMetadataGQL,
    variables: { updateUserMetadataInput: { ...metadata } },
  });

  return res.data;
};
