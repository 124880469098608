import { QueryOptions } from "@apollo/client";
import apollo from "../../../clients/apollo";
import { listLiveStreams } from "../../queries/liveStream";
import { Query } from "../../../gql/graphql";

const apolloClient = apollo.getInstance();

export const listLivestreams = async (
  options: Partial<QueryOptions> = {},
  userId?: string
): Promise<Query["livestreams"]> => {
  const res = await apolloClient.query({
    ...options,
    context: { apiName: "primaryLegacy" },
    query: listLiveStreams,
    variables: { livestreamFilters: { userId, includeClosedStreams: true } },
  });

  return res?.data?.livestreams;
};
