import {
  PayloadAction,
  createAsyncThunk,
  createAction,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { IEventRules } from "../types/eventRules";
import { AppDispatch, AppThunk, RootState } from "../store";
import { OnmoStorage } from "../models/onmoStorage";
import { GlobalLeaderboardsMd } from "../models/globalLeaderboards";
import { EventRules } from "../models/eventRules";
import {
  ContestsOutput,
  GlobalLeaderboard,
  GlobalLeaderboardItem,
  LeaderboardActivity,
  LeaderboardUserNotificationData,
  TournamentInfo,
} from "../gql/graphql";
import { Time } from "../models/time";
import { getContests } from "../graphql/resolvers/queries/contests";
import { setNotificationError, setNotificationSnackbar } from "./alert";
import { LeaderboardType } from "../constants/leaderboards";
import {
  getTournament,
  listMWCTournaments,
  listTournaments,
} from "../graphql/resolvers/queries/tournament";

interface IDiscover {
  tournamentList: TournamentInfo[] | null;
  globalLeaderboards: GlobalLeaderboard | null;
  eventRules: IEventRules[] | null;
  contests: ContestsOutput | null;
  historyGlobalLeaderboard: GlobalLeaderboardItem | undefined;
  tournamentMWC: TournamentInfo | null;
  tournamentLeaderboard: GlobalLeaderboardItem | undefined;
}

const initialState = {
  tournamentList: null,
  globalLeaderboards: null,
  eventRules: null,
  contests: null,
  historyGlobalLeaderboard: undefined,
  tournamentMWC: null,
  tournamentLeaderboard: undefined,
} as IDiscover;

export const resetDiscoverState = createAction("discover/resetState");

export const discoverSlice = createSlice({
  name: "discover",
  initialState: initialState,
  reducers: {
    updateGlobalLeaderboards: (
      state,
      action: PayloadAction<{ globalLeaderboards: GlobalLeaderboard | null }>
    ) => {
      state.globalLeaderboards = action.payload.globalLeaderboards;
    },
    updateTournaments: (
      state,
      action: PayloadAction<{ tournamentList: TournamentInfo[] }>
    ) => {
      state.tournamentList = action.payload.tournamentList;
    },
    updateTournamentsMWC: (
      state,
      action: PayloadAction<{ tournamentMWC: TournamentInfo | null }>
    ) => {
      state.tournamentMWC = action.payload.tournamentMWC;
    },
    updateEventRules: (
      state,
      action: PayloadAction<{ eventRules: IEventRules[] }>
    ) => {
      state.eventRules = action.payload.eventRules;
    },
    updateContests: (
      state,
      action: PayloadAction<{ contests: ContestsOutput }>
    ) => {
      state.contests = action.payload.contests;
    },
    updateHistoryGlobalLeaderboard: (
      state,
      action: PayloadAction<{
        historyGlobalLeaderboard: GlobalLeaderboardItem | undefined;
      }>
    ) => {
      state.historyGlobalLeaderboard = action.payload.historyGlobalLeaderboard;
    },
    updateTournamentLeaderboard: (
      state,
      action: PayloadAction<{
        tournamentLeaderboard: GlobalLeaderboardItem | undefined;
      }>
    ) => {
      state.tournamentLeaderboard = action.payload.tournamentLeaderboard;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGlobalLeaderboardProfile.rejected, (state) => {
        state.historyGlobalLeaderboard = undefined;
      })
      .addCase(getGlobalLeaderboardProfile.fulfilled, (state, action) => {
        state.historyGlobalLeaderboard = action.payload;
      })
      .addCase(resetDiscoverState, () => initialState);
  },
});

// Action creators are generated for each case reducer function
export const {
  updateGlobalLeaderboards: updateGlobalLeaderboardsAction,
  updateTournaments: updateTournamentsAction,
  updateEventRules: updateEventRulesAction,
  updateContests: updateContestsAction,
  updateHistoryGlobalLeaderboard,
  updateTournamentsMWC,
  updateTournamentLeaderboard,
} = discoverSlice.actions;

export const fetchTournamentList =
  (limit?: number): AppThunk =>
  async (dispatch) => {
    try {
      const tournamentList = await listTournaments({
        getLeaderboardInput: { limit },
      });
      OnmoStorage.setListTournament(JSON.stringify(tournamentList));
      dispatch(updateTournamentsAction({ tournamentList }));
    } catch (e) {
      if (e instanceof Error) {
        console.error(e?.message);
      }
      dispatch(updateTournamentsAction({ tournamentList: [] }));
    }
  };

export const fetchMWCTournamentList =
  (limit?: number): AppThunk =>
  async (dispatch) => {
    try {
      const tournamentList = await listMWCTournaments({
        getLeaderboardInput: { limit },
      });
      OnmoStorage.setListTournament(JSON.stringify(tournamentList));
      dispatch(updateTournamentsAction({ tournamentList }));
    } catch (e) {
      if (e instanceof Error) {
        console.error(e?.message);
      }
      dispatch(updateTournamentsAction({ tournamentList: [] }));
    }
  };

export const fetchTournamentMWC =
  (tournamentId: string): AppThunk =>
  async (dispatch) => {
    try {
      const tournamentMWC = await getTournament(
        { id: tournamentId, limit: 50 },
        { fetchPolicy: "network-only" }
      );
      dispatch(updateTournamentsMWC({ tournamentMWC }));
    } catch (e) {
      dispatch(setNotificationSnackbar((e as Error)?.message));
      dispatch(updateTournamentsMWC({ tournamentMWC: null }));
    }
  };

export const getGlobalLeaderboards = (): AppThunk => async (dispatch) => {
  try {
    const globalLeaderboards =
      await GlobalLeaderboardsMd.getGlobalLeaderboards();
    dispatch(updateGlobalLeaderboardsAction({ globalLeaderboards }));
    return globalLeaderboards;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e?.message);
    }
    dispatch(updateGlobalLeaderboardsAction({ globalLeaderboards: null }));
  }
};

export const fetchEventRules = (): AppThunk => async (dispatch) => {
  try {
    const eventRules = await EventRules.getEventRules();
    dispatch(updateEventRulesAction({ eventRules }));
    return eventRules;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e?.message);
    }
    dispatch(updateEventRulesAction({ eventRules: [] }));
  }
};

export const fetchContests = (): AppThunk => async (dispatch) => {
  try {
    const contests = await getContests();
    dispatch(updateContestsAction({ contests }));
    return contests;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e?.message);
    }
  }
};

export const getGlobalLeaderboardProfile = createAsyncThunk(
  "discover/getGlobalLeaderboardProfile",
  async (
    {
      activity,
      leaderBoardData,
    }: {
      activity: LeaderboardActivity | undefined;
      leaderBoardData: LeaderboardUserNotificationData | undefined;
    },
    { dispatch, signal }
  ) => {
    try {
      let leaderboardType = "";
      if (activity) {
        leaderboardType =
          GlobalLeaderboardsMd.getLeaderboardType(activity.type) || "";
      } else if (leaderBoardData) {
        leaderboardType =
          GlobalLeaderboardsMd.getLeaderboardType(
            leaderBoardData.leaderboardType
          ) || "";
      }
      const endDate = activity
        ? Time.convertTimestampToFormattedDate(parseInt(activity.endDate))
        : Time.convertTimestampToFormattedDate(
            leaderBoardData?.endDate as string
          );
      const historyGlobalLeaderboard =
        await GlobalLeaderboardsMd.getGlobalLeaderboardProfile(
          endDate,
          leaderboardType,
          {
            fetchPolicy: "network-only",
            context: {
              fetchOptions: {
                signal,
              },
            },
          }
        );

      return historyGlobalLeaderboard;
    } catch (e) {
      if (e instanceof Error) {
        (dispatch as AppDispatch)(setNotificationError(e.message));
      }
    }
  }
);

const globalLeaderboards = (state: RootState) =>
  state.discover.globalLeaderboards;

const historyGlobalLeaderboard = (state: RootState) =>
  state.discover.historyGlobalLeaderboard;

const tournamentLeaderboard = (state: RootState) =>
  state.discover.tournamentLeaderboard;

export const selectLeaderboard = createSelector(
  [
    globalLeaderboards,
    historyGlobalLeaderboard,
    tournamentLeaderboard,
    (state: RootState) => state.user.me,
    (
      state: RootState,
      options: {
        activeTab: string;
        leaderboardType?: LeaderboardType;
      }
    ) => options,
  ],
  (
    globalLeaderboards,
    historyGlobalLeaderboard,
    tournamentLeaderboard,
    me,
    { activeTab, leaderboardType }
  ) => {
    return GlobalLeaderboardsMd.fetchLeaderboardData(
      activeTab,
      globalLeaderboards,
      historyGlobalLeaderboard || null,
      tournamentLeaderboard,
      me,
      leaderboardType
    );
  }
);

export const selectGlobalLeaderboardItem = createSelector(
  [
    globalLeaderboards,
    historyGlobalLeaderboard,
    tournamentLeaderboard,
    (
      state: RootState,
      options: {
        activeTab: string;
        leaderboardType?: LeaderboardType;
      }
    ) => options,
  ],
  (
    globalLeaderboards,
    historyGlobalLeaderboard,
    tournamentLeaderboard,
    { activeTab, leaderboardType }
  ) => {
    return GlobalLeaderboardsMd.getLeaderboardData(
      activeTab,
      globalLeaderboards,
      historyGlobalLeaderboard || null,
      tournamentLeaderboard,
      leaderboardType
    );
  }
);

export const selectQualifiedContests = createSelector(
  [(state: RootState) => state.discover.contests],
  (contests) => {
    return contests?.items.filter((item) => item.isQualified);
  }
);

export const tournamentMWCSelector = createSelector(
  [
    (state: RootState) => state.discover.tournamentList,
    (state: RootState) => state.discover.tournamentMWC,
    (state: RootState, tournamentId?: string) => tournamentId,
  ],
  (tournamentList, tournamentMWC, tournamentId) => {
    const theMostPlayerInLeaderboards = tournamentList?.reduce((a, b) => {
      return a.leaderboards.length > b.leaderboards.length ? a : b;
    });

    if (tournamentId) {
      return tournamentMWC;
    } else return theMostPlayerInLeaderboards;
  }
);

export default discoverSlice.reducer;
