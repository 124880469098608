import { compact, concat } from "lodash-es";
import Rank1Icon from "../assets/competition/rank1_2x.webp";
import Rank2Icon from "../assets/competition/rank2_2x.webp";
import Rank3Icon from "../assets/competition/rank3_2x.webp";
import OutTopIcon from "../assets/competition/out-top_2x.webp";
import { getGlobalLeaderboards } from "../graphql/resolvers/mutation/globalLeaderboards";
import { ILeaderboardsInput } from "../types/leaderboards";
import { getGlobalLeaderboardProfile } from "../graphql/resolvers/queries/globalLeaderboards";
import {
  GlobalLeaderboard,
  GlobalLeaderboardItem,
  LeaderboardActivity,
  LeaderboardUserNotificationData,
  Leaderboard_Type,
  TimeFrame,
  TournamentInfo,
} from "../gql/graphql";
import { LeaderboardType, TimeLine } from "../constants/leaderboards";
import { TFunction } from "i18next";
import { Month } from "../constants/month";
import { QueryOptions } from "@apollo/client";
import { formatScoreByMomentType } from "./gamesession/gamesession";
import { User } from "../gqlV2/graphql";

export class GlobalLeaderboardsMd {
  static getGlobalLeaderboards = () => getGlobalLeaderboards();

  static getGlobalLeaderboardProfile = (
    endDate: string,
    leaderboardType: string,
    options: Partial<QueryOptions> = {}
  ) => getGlobalLeaderboardProfile(endDate, leaderboardType, options);

  static addRankForPlayer = (leaderboard: ILeaderboardsInput[] | undefined) => {
    if (!leaderboard) return null;
    return leaderboard.map(
      (leaderboard: ILeaderboardsInput, index: number) => ({
        ...leaderboard,
        rank: index + 1,
      })
    );
  };

  static getListOutTop3Lb = (
    leaderboard: ILeaderboardsInput[] | undefined,
    user: User | undefined | null,
    myRank: number,
    myScore: number
  ) => {
    if (!leaderboard || !user) return null;

    const newLb = this.addRankForPlayer(leaderboard);
    if (!newLb || newLb.length < 4) return null;

    const outTop3Lb = newLb.slice(3);
    const outTop3LbNoMyRanking = outTop3Lb.filter((l) => l.id !== user.id);
    const myRanking = {
      banner: "",
      id: user.id,
      avatar: user.avatar,
      username: user.username,
      xp: user.xp,
      rank: myRank + 1,
      score: myScore,
    };

    return compact(
      concat(
        [myRanking?.rank > 3 && myRanking?.score !== -1 && myRanking],
        outTop3LbNoMyRanking
      )
    );
  };

  static getTop3Lb = (leaderboard?: ILeaderboardsInput[]) => {
    if (!leaderboard) return null;
    const listTop3Lb = leaderboard.slice(0, 3);
    const sortedRank = [2, 1, 3];
    return sortedRank.map((rank) => {
      const user = listTop3Lb.find((_, i) => i === rank - 1);
      return { ...user, rank, type: "top3" };
    }) as ILeaderboardsInput[];
  };

  static getShareBackground = (rank: number) => {
    switch (rank) {
      case 1:
        return Rank1Icon;
      case 2:
        return Rank2Icon;
      case 3:
        return Rank3Icon;
      default:
        return OutTopIcon;
    }
  };

  static getTitleDate = (
    t: TFunction,
    activity?: LeaderboardActivity,
    leaderBoardData?: LeaderboardUserNotificationData
  ) => {
    const date = new Date(
      leaderBoardData
        ? leaderBoardData?.endDate
        : JSON?.parse(activity?.endDate ?? "")
    );
    const thisMonth = Month[date.getUTCMonth()];
    const typeLeaderboard =
      activity?.type === "global-leaderboard-weekly" ||
      leaderBoardData?.timeFrame === TimeFrame.Weekly
        ? TimeLine.Weekly
        : thisMonth;

    return activity?.type === "global-leaderboard-contests" ||
      leaderBoardData?.name
      ? t("common_Contest Leaderboard", { name: activity?.name })
      : t(`competition_${typeLeaderboard} Leaderboard`);
  };

  static getLeaderboardType = (type: string) => {
    if (type === "global-leaderboard-contests") {
      return Leaderboard_Type.Contest;
    } else if (type === "global-leaderboard-monthly") {
      return Leaderboard_Type.Monthly;
    } else if (type === "global-leaderboard-weekly") {
      return Leaderboard_Type.Weekly;
    }
  };

  static leaderboardFromTournament = (
    tournament?: TournamentInfo,
    myUserId?: string
  ) => {
    if (!tournament) return undefined;
    const myScore =
      tournament.leaderboards.find((data) => data.userId === myUserId)?.score ??
      0;

    return {
      endDate: tournament?.expiredAt,
      leaderboard: tournament?.leaderboards.map((data) => {
        return {
          ...data.user,
          score: formatScoreByMomentType(
            data.score as number,
            tournament?.moment.type
          ),
          __typename: "LeaderboardUser",
        };
      }),
      myRank: tournament && tournament?.myRank - 1,
      myScore: formatScoreByMomentType(
        myScore as number,
        tournament?.moment.type
      ),
      __typename: "GlobalLeaderboardItem",
    } as GlobalLeaderboardItem;
  };

  static getLeaderboardData = (
    activeTab: string,
    globalLeaderboards: GlobalLeaderboard | null,
    historyGlobalLeaderboard: GlobalLeaderboardItem | null,
    tournamentLeaderboard?: GlobalLeaderboardItem | null,
    leaderboardType?: LeaderboardType
  ) => {
    if (leaderboardType === LeaderboardType.GlobalLeaderboard) {
      if (activeTab === TimeLine.Weekly) {
        return globalLeaderboards?.weekly;
      } else if (activeTab === TimeLine.Monthly) {
        return globalLeaderboards?.monthly;
      } else if (activeTab === TimeLine.AllTime) {
        return globalLeaderboards?.alltime;
      }
    } else if (
      leaderboardType === LeaderboardType.Tournament ||
      leaderboardType === LeaderboardType.TournamentOnGoing
    ) {
      return tournamentLeaderboard;
    } else if (leaderboardType === LeaderboardType.HistoryGlobalLeaderboard) {
      return historyGlobalLeaderboard;
    }
  };

  static fetchLeaderboardData = (
    activeTab: string,
    globalLeaderboards: GlobalLeaderboard | null,
    historyGlobalLeaderboard: GlobalLeaderboardItem | null,
    tournamentLeaderboard?: GlobalLeaderboardItem | null,
    me?: User | null,
    leaderboardType?: LeaderboardType
  ) => {
    const getGlobalLeaderboards = this.getLeaderboardData(
      activeTab,
      globalLeaderboards,
      historyGlobalLeaderboard,
      tournamentLeaderboard,
      leaderboardType
    );

    return this.getTop3Lb(getGlobalLeaderboards?.leaderboard)?.concat(
      this.getListOutTop3Lb(
        getGlobalLeaderboards?.leaderboard,
        me,
        getGlobalLeaderboards?.myRank || 0,
        getGlobalLeaderboards?.myScore || 0
      ) || []
    );
  };
}
